import { Container, ArticleImg, Row, Column } from "./styles";

import Card from "../../components/Card";
import SearchBar from "../../components/SearchBar";
import SectionTitle from "../../components/SectionTitle";
import extractDate from "../../functions/extractDate";
import getArtigos from "../../functions/getArtigos";
import getArtigosById from "../../functions/getArtigosById";
import DefaultImg from "../../icons/DefaultImg";

import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";

const BlogPost = ({ apiCards, handleOpenArticle }) => {
  /**
   * Página que mostra post, artigo do blog
   */

  const [showCards, setShowCards] = useState(null);
  const [article, setArticle] = useState(null);

  const loadArticle = async () => {
    /** Carrega posts da api e salva em state */
    /** No fututo, fará request à api utilizando a variavel articleId */
    const articleId = window.location.href.split("?")[1];
    const newArticle = await getArtigosById(articleId);
    console.log("newArticle: ", newArticle);
    setArticle(newArticle.data);
  };

  const loadCards = async () => {
    let params = "";
    let apiArtigos = await getArtigos(params);
    setShowCards(apiArtigos.data);
  };

  useEffect(() => console.log(article), [article]);

  const handleCardClick = id => {
    handleOpenArticle(id);
    loadArticle();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadArticle();
    loadCards();
  }, []);

  if (article) {
    return (
      <Container>
        <SectionTitle>{article.title.rendered}</SectionTitle>
        <ArticleImg>
          {article.better_featured_image ? (
            <img
              src={article.better_featured_image.source_url}
              alt="imagem-post"
            />
          ) : (
            <div id="bg-default">
              <DefaultImg />
            </div>
          )}
        </ArticleImg>
        <Row>
          <Column id="text">
            <h2>{article.title.rendered}</h2>
            <div>{HtmlParser(article.content.rendered)}</div>
            <div id="data">
              <span>Data da postagem:</span> {extractDate(article.date)}
            </div>
          </Column>
          <Column id="cards">
            <SearchBar placeholder="pesquisar um artigo" />
            <h2>Últimos Artigos</h2>
            {showCards
              ? showCards.map((e, i) =>
                  i < 2 ? (
                    <Card
                      type={4}
                      imgUrl={
                        e.better_featured_image
                          ? e.better_featured_image.source_url
                          : null
                      }
                      title={e.title.rendered}
                      excerpt={HtmlParser(e.excerpt.rendered)}
                      date={extractDate(e.date)}
                      btnContent="Ver artigo"
                      btnAction={() => handleCardClick(e.id)}
                    />
                  ) : null
                )
              : null}
          </Column>
        </Row>
      </Container>
    );
  } else return <p>Carregando...</p>;
};

export default BlogPost;
