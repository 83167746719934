import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-content: center;
  padding: 15px;
`;

export const H1 = styled.h1`
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
`;

export const Line = styled.hr`
  margin: 0 auto;
  width: 10%;
  height: 4px;
  background: #192e59;
  border-radius: 4px;
  border: none;
  min-width: 63px;
`;
