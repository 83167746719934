import { Container, CardsArea, FilterArea, FilterBtn } from "./styles";

import Card from "../../components/Card";
import SearchBar from "../../components/SearchBar";
import SectionTitle from "../../components/SectionTitle";
import getArtigos from "../../functions/getArtigos";
import getTags from "../../functions/getTags";

import React, { useEffect, useState } from "react";

const BlogList = ({ handleOpenArticle }) => {
  /** Lista de cards (artigos) da página Blog */

  const [cards, setCards] = useState(null);
  const [showCards, setShowCards] = useState(null);
  const [tags, setTags] = useState(null);

  const loadData = async (req, save) => {
    /** Trigger de requisições. req = request(), save = setState() correspondente. */
    let apiData = await req();
    save(apiData.data);
  };

  const loadCards = async () => {
    let params = "";
    let apiArtigos = await getArtigos(params);
    setCards(apiArtigos.data);
    setShowCards(apiArtigos.data);
  };

  useEffect(() => {
    loadData(getTags, setTags);
    loadCards();
    window.scrollTo(0, 0);
  }, []);

  const filterCards = async e => {
    /** Pesquisa título e excerpt do card */
    const newCards = cards.filter(
      card =>
        card.title.rendered.toLowerCase().includes(e.toLowerCase()) ||
        card.excerpt.rendered.toLowerCase().includes(e.toLowerCase())
    );
    setShowCards(newCards);
  };

  const filterCardsByTag = tag => {
    /** Filtra cards pela Tag */
    const newCards = cards.filter(card => card.tags.indexOf(tag) > -1);
    setShowCards(newCards);
  };

  return (
    <Container>
      <SectionTitle>Artigos</SectionTitle>
      <SearchBar
        placeholder="Pesquisar um artigo"
        onChange={e => filterCards(e.target.value)}
      />
      <FilterArea>
        <h2>Filtrar:</h2>
        {tags
          ? tags.map(tag => (
              <FilterBtn onClick={() => filterCardsByTag(tag.id)}>
                #{tag.name}
              </FilterBtn>
            ))
          : null}
      </FilterArea>
      <CardsArea>
        {showCards
          ? showCards.map(article => (
              <Card
                type={3}
                imgUrl={
                  article.better_featured_image
                    ? article.better_featured_image.source_url
                    : null
                }
                title={article.title.rendered}
                excerpt={article.excerpt.rendered
                  .replace("<p>", "")
                  .replace("</p>", "")
                  .replace("[&hellip;]", "")}
                date={article.date}
                btnContent="Continuar lendo"
                btnAction={() => handleOpenArticle(article.id)}
              />
            ))
          : null}
      </CardsArea>
    </Container>
  );
};

export default BlogList;
