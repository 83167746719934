import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ArticleImg = styled.div`
  overflow: hidden;
  margin-top: 7vw;
  width: 100%;
  height: fit-content;
  max-height: 450px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  #bg-default {
    width: 100%;
    max-height: 450px;
    height: 50vw;
    background: #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 400px) {
    width: 90%;
    margin: 7vw auto 0;
  }

  img {
    width: 100%;
  }
`;

export const Row = styled.div`
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-flow: column;
  margin: 20px 10px;

  &#text {
    flex: 6 1 250px;

    h2 {
      font-size: 22px;
      margin-bottom: 40px;
    }

    > div {
      padding: 0 10px 0 0;

      h2 {
        margin: 20px 0 10px;
      }

      img {
        width: 100%;
        margin: 15px 0;
      }
    }

    p {
      text-align: justify;
    }

    #data {
      margin-top: 50px;

      @media screen and (max-width: 768px) {
        margin-top: 25px;
      }

      span:first-of-type {
        color: ${props => props.theme.primaryBlue};
        font-weight: bold;
      }
    }
  }

  &#cards {
    flex: 1 1 180px;
    align-items: center;

    .MuiFormControl-root {
      /* width: 335px; */
      width: 100%;
      transform: scale(0.9);
    }

    h2 {
      text-align: center;
      margin: 40px 0;
    }
  }

  .MuiPaper-root {
    margin-bottom: 40px;
  }
`;
