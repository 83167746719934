import {
  Container,
  Section,
  CardsArea,
  ParallaxContent,
  MapContainer,
  ContactHeader,
  WhatsappBtn,
  CarouselWrapper,
} from "./styles";

import InsurancesSection from "./components/InsurancesSection";

import Button from "../../components/Button";
import Card from "../../components/Card";
import CardsCarousel from "../../components/CardsCarousel";
import ContactForm from "../../components/ContactForm";
import Header from "../../components/Header";
import Carousel from "../../components/HomeCarousel";
import SectionTitle from "../../components/SectionTitle";
import getPosts from "../../functions/getPosts";
import getServicos from "../../functions/getServicos";
import getSobre from "../../functions/getSobre";
import scrollWidthOffset from "../../functions/scrollOfset";
import SvgLocationPin from "../../icons/LocationPin";
import WhatsApp from "../../icons/Whatsapp";

import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Map, Marker, TileLayer, Tooltip } from "react-leaflet";
import OnVisible from "react-on-visible";
import { Parallax } from "react-parallax";
import { HashLink } from "react-router-hash-link";
import windowSize from "react-window-size";

const HomePage = ({ history, windowWidth }) => {
  /**
   * Página Home
   */

  const [headerVisible, setHeaderVisible] = useState(true);
  const [posts, setPosts] = useState(null);
  const [servicos, setServicos] = useState(null);
  const [sobre, setSobre] = useState(null);

  const loadData = async (req, save) => {
    /** Trigger de requisições. req = request(), save = setState() correspondente. */
    let apiData = await req();
    console.log(apiData);
    save(apiData.data);
  };

  useEffect(() => {
    loadData(getPosts, setPosts);
    loadData(getServicos, setServicos);
    loadData(getSobre, setSobre);
  }, []);

  useEffect(() => console.log("posts: ", posts), [posts]);
  useEffect(() => console.log("sobre: ", sobre), [sobre]);
  useEffect(() => console.log("servicos: ", servicos), [servicos]);

  const handleVisibleHeader = arg => {
    if (arg) setHeaderVisible(false);
    else setHeaderVisible(true);
  };

  const handleOpenArticle = postId => {
    history.push(`/blog/post?${postId}`);
  };

  return (
    <Container id="top">
      <Header headerVisible={headerVisible} />

      {/* Carousel Section */}
      <CarouselWrapper>
        <Carousel slidesToShow={1} wrapAround={true} />
        {windowWidth > 768 ? (
          <a
            href={
              "https://api.whatsapp.com/send?phone=5571997422170&text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20pelo%20site%20Lithocenter."
            }
          >
            <WhatsappBtn
              onClick={() => console.log("Contato via whatsapp")}
              green
            >
              <WhatsApp />
              Agendar via WhatsApp
            </WhatsappBtn>
          </a>
        ) : null}
      </CarouselWrapper>

      {/* Cards Section 'Especialidades' */}
      <>
        <OnVisible percent={1} bounce={true} onChange={handleVisibleHeader}>
          <Section id="especialidades">
            {windowWidth <= 768 && (
              <a
                href={
                  "https://api.whatsapp.com/send?phone=5571997422170&text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20pelo%20site%20Lithocenter."
                }
              >
                <WhatsappBtn
                  onClick={() => console.log("Contato via whatsapp")}
                  green
                >
                  <WhatsApp />
                  Agendar via WhatsApp
                </WhatsappBtn>
              </a>
            )}

            <SectionTitle>
              Tudo para a saúde do homem em <br />
              um só <strong>centro de referência</strong>
            </SectionTitle>
            <CardsArea>
              <div className="section-title">
                <h1>Serviços:</h1>
              </div>
              <CardsCarousel
                slidesToShow={3}
                slidesToScroll={2}
                cellSpacing={20}
                framePadding="64px 6.5%"
              >
                {servicos
                  ? servicos.map(serv => (
                      <Card
                        type={1}
                        title={serv.title.rendered}
                        excerpt={ReactHtmlParser(serv.excerpt.rendered)}
                        btnContent={"Ler mais"}
                        btnAction={() => handleOpenArticle(serv.id)}
                      />
                    ))
                  : null}
              </CardsCarousel>
            </CardsArea>
          </Section>
        </OnVisible>
      </>

      {/* Parallax Section 'Sobre' */}
      <>
        <Parallax
          bgImage={
            sobre
              ? sobre[0].better_featured_image !== null
                ? sobre[0].better_featured_image.source_url
                : "/assets/Banner4.jpg"
              : ""
          }
          bgImageAlt="parallax image"
          strength={440}
        >
          <ParallaxContent>
            <div>
              <div>
                <h1>{sobre ? sobre[0].title.rendered : null}</h1>
                {sobre ? ReactHtmlParser(sobre[0].content.rendered) : null}
                <HashLink scroll={el => scrollWidthOffset(el)} to="/#contato">
                  <Button>Entre em contato</Button>
                </HashLink>
              </div>
            </div>
          </ParallaxContent>
        </Parallax>
      </>

      <InsurancesSection />

      {/* Cards Section 'Artigos' */}
      <>
        <Section id="artigos">
          <CardsArea>
            <div className="section-title">
              <h1>Artigos do Blog</h1>
              <Button outlined onClick={() => history.push("/blog/list")}>
                Mais Artigos
              </Button>
            </div>
            {posts ? (
              <CardsCarousel
                slidesToShow={3}
                slidesToScroll={2}
                cellSpacing={20}
                framePadding="64px 6.5%"
              >
                {posts.map(post => (
                  <Card
                    type={2}
                    imgUrl={
                      post.better_featured_image
                        ? post.better_featured_image.source_url
                        : null
                    }
                    title={post.title.rendered}
                    excerpt={ReactHtmlParser(post.excerpt.rendered)}
                    btnContent={"Ler mais"}
                    btnAction={() => handleOpenArticle(post.id)}
                  />
                ))}
              </CardsCarousel>
            ) : null}
          </CardsArea>
        </Section>
      </>

      {/* Section 'Como chegar' */}
      <>
        <Section id="localizacao" className="light-grey como-chegar">
          <SectionTitle>
            <strong>Hospital Dia:</strong> saiba como chegar
          </SectionTitle>
          <hr />
          <MapContainer>
            <Map
              center={[-12.998735, -38.516944]} //windowWidth > 600 ? [-12.998735, -38.518944] : [-12.998735, -38.517844]}
              zoom={15}
              zoomControl={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker opacity={0} position={[-12.998735, -38.517844]}>
                <Tooltip
                  permanent={true}
                  direction={"top"}
                  className="transparent-tooltip"
                  offset={[1, 15]}
                  onClick={e => console.log("oiii")}
                >
                  <div className="scene">
                    <div className="cube">
                      <div className="cube__face cube__face--front">
                        <div className="img-wrapper">
                          <img
                            src="/assets/Banner1.jpg"
                            alt="banner lithocenter"
                          />
                        </div>
                        <div className="arrow arrow--front"></div>
                      </div>
                      <div className="cube__face cube__face--back">
                        <div className="img-wrapper">
                          <img
                            src="/assets/Banner1.jpg"
                            alt="banner lithocenter"
                          />
                        </div>
                        <div className="arrow arrow--back"></div>
                      </div>
                      <div className="cube__face cube__face--right"></div>
                      <div className="cube__face cube__face--left"></div>
                      <div className="cube__face cube__face--top"></div>
                      <div className="cube__face cube__face--bottom"></div>
                      <div className="cube__face cube__face--shadow"></div>
                    </div>
                  </div>
                  <SvgLocationPin />
                </Tooltip>
              </Marker>
            </Map>
            <Button
              onClick={() =>
                window.open("https://goo.gl/maps/zTyp848vGzww3CFA8", "_blank")
              }
              className="navegar-bt"
            >
              Navegar para Lithocenter
            </Button>
          </MapContainer>
        </Section>
      </>

      {/* Contato Section */}

      <>
        <Section id="contato">
          <ContactHeader>
            <h1>Enviar mensagem</h1>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Vivamus vestibulum et sapien sed commodo. </p> */}
          </ContactHeader>
          <ContactForm />
        </Section>
      </>
    </Container>
  );
};

export default windowSize(HomePage);
