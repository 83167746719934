import Button from "../../components/Button";

import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  @media screen and (max-width: 600px) {
    .react-parallax-bgimage {
      left: 10% !important;
      margin-top: 30px;
    }
  }
`;

export const Section = styled.div`
  position: relative;
  background: #fff;
  min-height: calc(100vh - 86px);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 70px;

  &.parallax {
    background: transparent;

    > div > img {
      position: absolute;
      bottom: 0;
      width: 42%;
    }
  }

  &.light-grey {
    background: #e5e5e5;
  }

  &#localizacao {
    min-height: unset;
    height: fit-content;
    > div:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const CardsArea = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;
  display: flex;
  flex-flow: column;
  width: 100%;

  .section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 90px;
    box-sizing: border-box;
    max-height: 65px;
    > h1:first-of-type {
      margin: 0;
    }

    @media only screen and (max-width: 768px) {
      padding: 0 45px;
    }
  }
`;

export const ParallaxContent = styled.div`
  min-height: calc(100vh - 86px);
  display: flex;
  flex-flow: column;
  align-items: center;
  background: transparent;

  @media screen and (max-width: 600px) {
    min-height: 110vh;
    margin-top: 30px;
  }

  > div {
    /* display: none; */
    position: absolute;
    bottom: -100px;
    left: -40px;
    width: 45%;
    padding: 90px 0 70px 0;
    transform: rotate(4deg) skewY(10deg);
    border-top-right-radius: 55px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    background: ${props => props.theme.primaryGreen};

    @media screen and (max-width: 1200px) {
      width: 50%;
    }

    @media screen and (max-width: 1024px) {
      width: 60%;
    }

    @media screen and (max-width: 800px) {
      bottom: -180px;
      left: -30px;
      width: 120%;
      padding: 50px 0 70px 0;
      transform: rotate(-2deg) skewY(10deg);
      border-top-right-radius: 0;
      justify-content: flex-start;
    }

    > div {
      width: 60%;
      transform: rotate(-4deg) skewY(-10deg);
      margin-bottom: 100px;

      @media screen and (max-width: 800px) {
        width: 80vw;
        transform: rotate(2deg) skewY(-10deg);
        margin-bottom: 150px;
        margin-left: -50px;
      }
      @media screen and (max-width: 800px) {
        margin-left: -20px;
      }

      h1 {
        margin-bottom: 22px;
        text-align: left;
        z-index: 1;
        font-weight: 400;
      }

      p {
        color: #fff;
        z-index: 1;
      }

      a {
        color: #fff;
        z-index: 1;
        transition: color 0.2s;
        text-decoration: underline solid;

        &:hover {
          color: ${props => props.theme.primaryBlue};
          /* text-decoration: underline solid ; */
        }
      }

      > a:last-of-type {
        text-decoration: none;
      }

      .MuiButton-root {
        margin-top: 40px;
        width: fit-content;

        @media screen and (max-width: 800px) {
          margin-top: 29px;
        }
      }
    }
  }
`;

export const MapContainer = styled.div`
  height: 65vh;
  width: 100%;
  z-index: 1;
  transform-style: flat;
  perspective: 5000px;
  overflow: hidden;

  .navegar-bt {
    position: absolute;
    bottom: 20px;
    right: 25px;
    padding: 10px 15px;

    @media screen and (max-width: 600px) {
      right: 50%;
      transform: translateX(50%);
    }

    .MuiButton-label {
      font-size: 16px;
    }
  }

  .leaflet-container {
    width: 150%;
    height: 142%;
    transform: rotateX(45deg);
    margin-top: -15vh;
    margin-left: -15%;
    background: transparent;

    .leaflet-marker-icon {
      cursor: unset;
    }

    .leaflet-marker-pane {
      display: none !important;
    }

    .transparent-tooltip {
      opacity: 1 !important;
      /* background: red; */
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      margin-top: 13px;
      display: flex;
      flex-flow: column;
      align-items: center;
      /* perspective: 1000px; */

      .scene {
        width: 100px;
        height: 100px;
        perspective: 1000px;
        /* background: blue; */
        background: transparent;
        transform: translateY(-80px) !important;
      }

      .cube {
        width: 100%;
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        transform: translateZ(200px) rotateX(-45deg);
        /* transform: translateZ(200px) rotateX(120deg); */
        transform-origin: 50%;
        /* transition: transform 1s; */
        animation: spincube 5s linear infinite;
      }

      @keyframes spincube {
        0% {
          transform: translateZ(200px) rotateX(-45deg) rotateY(0deg);
        }
        100% {
          transform: translateZ(200px) rotateX(-45deg) rotateY(360deg);
        }
      }

      .cube__face {
        position: absolute;

        .img-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          overflow: hidden;

          img {
            height: 100%;
          }
        }

        &--front,
        &--back {
          height: 150px;
          width: 100px;
        }

        &--left,
        &--right {
          height: 150px;
          width: 10px;
        }

        &--top,
        &--bottom {
          height: 10px;
          width: 100px;
        }

        &--shadow {
          transform: rotateX(-90deg) translateZ(250px);
          width: 100px;
          height: 0px;
          box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.5);
          filter: blur(10);
        }

        .arrow {
          position: absolute;

          &.arrow--front,
          &.arrow--back {
            width: 0;
            height: 0;
            bottom: -50px;
            left: 30px;
            border: 20px solid transparent;
            border-top: 30px solid ${props => props.theme.primaryBlue};
            z-index: 5;
          }
        }
      }

      .cube__face--front {
        transform: rotateY(0deg) translateZ(5px);
      }
      .cube__face--back {
        transform: rotateY(180deg) translateZ(5px);
      }

      .cube__face--right {
        transform: rotateY(90deg) translateZ(95px);
        background: ${props => props.theme.primaryBlue};
      }

      .cube__face--left {
        transform: rotateY(-90deg) translateZ(5px);
        background: ${props => props.theme.primaryBlue};
      }

      .cube__face--top {
        transform: rotateX(90deg) translateZ(5px);
        background: ${props => props.theme.primaryBlue};
      }

      .cube__face--bottom {
        transform: rotateX(-90deg) translateZ(150px);
        background: ${props => props.theme.primaryBlue};
      }

      .cube.show-front {
        transform: translateZ(-100px) rotateY(0deg);
      }
      .cube.show-right {
        transform: translateZ(-100px) rotateY(-90deg);
      }
      .cube.show-back {
        transform: translateZ(-100px) rotateY(-180deg);
      }
      .cube.show-left {
        transform: translateZ(-100px) rotateY(90deg);
      }
      .cube.show-top {
        transform: translateZ(-100px) rotateX(-90deg);
      }
      .cube.show-bottom {
        transform: translateZ(-100px) rotateX(90deg);
      }

      &:hover {
        cursor: pointer !important;
      }

      a#gmap-link {
        font-size: 20px;
        margin-bottom: 10px;
        transform: rotateX(-45deg);
      }

      > svg {
        transform: scale(1.6);
        transform: rotateX(-45deg);
      }

      &::before {
        display: none;
      }
    }
  }
`;

export const ContactHeader = styled.div`
  max-width: 632px;

  @media only screen and (max-width: 600px) {
    padding: 0 30px;
    text-align: center;
  }

  h1 {
    text-align: left;
    @media only screen and (max-width: 600px) {
      margin-bottom: 15px;
      text-align: center;
    }
  }

  p {
    color: ${props => props.theme.primaryBlue};
  }
`;

export const WhatsappBtn = styled(Button)`
  &.MuiButton-root {
    position: absolute;
    right: 70px;
    margin-left: auto;
    bottom: 40px;
    background: ${props => props.theme.primaryGreen};
    &:hover {
      background: ${props => props.theme.primaryGreen};
    }

    .MuiButton-label {
      svg {
        margin-right: 8px;
      }
    }

    @media screen and (max-width: 768px) {
      position: relative;
      right: unset;
      margin-left: unset;
      bottom: 20px;
    }
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  height: calc(100vh - 86px);

  @media only screen and (max-width: 600px) {
    height: calc(100vh - 10px);
  }
  @media screen and (max-width: 350px) {
    height: calc(100vh + 100px);
  }
`;
