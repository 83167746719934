import { Container, H1, Line } from "./styles";

import PropTypes from "prop-types";
import React from "react";

const Title = ({ children }) => {
  return (
    <Container>
      <H1>{children}</H1>
      <Line />
    </Container>
  );
};

Title.propTypes = {
  children: PropTypes.element,
};

export default Title;
