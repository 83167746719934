import axios from "axios";

const getPosts = () => {
  /* Busca posts das seguintes categorias
   * 1: Uncategorized
   * 3: Serviços
   * 4: Artigos
   */
  return (
    axios
      // WP-API v1 (http://wp-api.org/misc/compatibility.html#http-method-compatibility) deprecated, but useful
      // .get(`${process.env.REACT_APP_API_HOST}/wp/v2/posts?filter[category_name]=Artigos&filter[category_name]=${encodeURIComponent('Serviços')}`)
      .get(`${process.env.REACT_APP_API_HOST}/wp/v2/posts?categories=1,3,4`)
      .then(res => {
        console.log("getPosts: ", res);
        return res;
      })
      .catch(err => {
        console.log("Ocorreu um erro: ", err);
      })
  );
};

export default getPosts;
