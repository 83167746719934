import React from "react";

const SvgLocationPin = props => (
  <svg width={16} height={21} fill="none" {...props}>
    <path
      d="M7.178 20.576C1.124 11.936 0 11.05 0 7.875 0 3.525 3.582 0 8 0s8 3.526 8 7.875c0 3.175-1.124 4.062-7.178 12.701a1.01 1.01 0 01-1.644 0zM8 11.156c1.841 0 3.333-1.469 3.333-3.281S9.841 4.594 8 4.594c-1.841 0-3.333 1.469-3.333 3.281S6.159 11.156 8 11.156z"
      fill="#192E59"
    />
  </svg>
);

export default SvgLocationPin;
