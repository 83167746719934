import Slider from "react-slick";
import styled from "styled-components";

export default styled(Slider)`
  width: 90%;
  margin: 0;
  padding: 0;
  margin-top: 60px;
  box-sizing: border-box;
  text-align: center;

  .insurance_card {
    padding: 5px 5px 10px 5px;
    width: 100%;
    box-sizing: border-box;
  }

  img {
    width: 80%;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 15px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const Wrap = styled.section`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 70px 10px;
  background: #e5e5e5;
`;

export const SearchInput = styled.div`
  position: relative;
  margin: 0 auto;
  margin-top: 20px;

  button {
    font-family: "Arvo", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    padding: 14px 21px;
    border: 2px solid #192e59;
    color: #192e59;
    box-sizing: border-box;
    border-radius: 10px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  div.input_wrap {
    padding: 14px 21px;
    border: 2px solid #192e59;
    color: #192e59;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;

    @media (max-width: 768px) {
      padding: 0 21px;
    }

    label {
      position: absolute;
      top: 18px;
      left: 20px;

      @media (max-width: 768px) {
        top: 8px;
      }
    }

    input {
      font-family: "Arvo", serif;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 37px;
      background: none;
      padding: 0 0 0 40px;
      border: none;
      width: 400px;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 48px;
        width: 250px;
      }
    }
  }
`;

export const Arrow = styled.button`
  position: relative;
  margin: 0 auto;
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  outline: 0;
  padding: 5px;
  border-radius: 3px;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  svg {
    path {
      stroke: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.next {
    top: 50%;
    right: -20px;
    transform: translateY(-50%);

    svg {
      transform: rotate(180deg);
    }
  }

  &.prev {
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
  }
`;
