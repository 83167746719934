import React from "react";

const SvgLeftArrowHeader = props => (
  <svg width={10} height={17} fill="none" {...props}>
    <path
      d="M8 2L2 8.5 8 15"
      stroke="#00AF98"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLeftArrowHeader;
