import React from "react";

const SvgArrow = props => (
  <svg width={18} height={16} fill="none" {...props}>
    <path
      d="M1 8h15m0 0l-5.893 7M16 8l-5.893-7"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgArrow;
