import React from "react";

const SvgFacebook = props => (
  <svg width={13} height={24} fill="none" {...props}>
    <path
      d="M12.085 13.5l.666-4.343H8.584V6.338c0-1.188.582-2.347 2.448-2.347h1.895V.293S11.207 0 9.564 0C6.132 0 3.888 2.08 3.888 5.846v3.31H.073V13.5h3.815V24h4.696V13.5h3.5z"
      fill="#192E59"
    />
  </svg>
);

export default SvgFacebook;
