import StyledSlider, { Wrap, SearchInput, Arrow } from "./styles";

import getInsurances from "functions/getInsurances";
import slugify from "functions/slugify";
import "assets/css/slick.css";
import "assets/css/slick-theme.css";
import ArrowIcon from "icons/CarouselArrow";

import SectionTitle from "components/SectionTitle";
import React, { useState, useEffect, useRef } from "react";

const SearchIcon = () => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.592 26.8015L23.7498 20.7656C23.4861 20.4931 23.1286 20.3418 22.7536 20.3418H21.7985C23.4158 18.2047 24.3768 15.5166 24.3768 12.5925C24.3768 5.63636 18.9213 0 12.1884 0C5.45548 0 0 5.63636 0 12.5925C0 19.5487 5.45548 25.185 12.1884 25.185C15.0187 25.185 17.6204 24.1922 19.6889 22.5212V23.5081C19.6889 23.8955 19.8354 24.2648 20.0991 24.5373L25.9414 30.5732C26.4922 31.1423 27.3829 31.1423 27.9278 30.5732L29.5861 28.8599C30.137 28.2908 30.137 27.3706 29.592 26.8015ZM12.1884 20.3418C8.04551 20.3418 4.68784 16.8788 4.68784 12.5925C4.68784 8.31227 8.03965 4.84328 12.1884 4.84328C16.3313 4.84328 19.6889 8.30622 19.6889 12.5925C19.6889 16.8728 16.3371 20.3418 12.1884 20.3418Z"
      fill="#192E59"
    />
  </svg>
);

const NextArrow = props => {
  // eslint-disable-next-line react/prop-types
  const { onClick } = props;
  return (
    <Arrow className="next" type="button" onClick={onClick}>
      <ArrowIcon />
    </Arrow>
  );
};

const PrevArrow = props => {
  // eslint-disable-next-line react/prop-types
  const { onClick } = props;
  return (
    <Arrow className="prev" type="button" onClick={onClick}>
      <ArrowIcon />
    </Arrow>
  );
};

export default () => {
  const [convenios, setConvenios] = useState(null);
  const [filteredConvenios, setFilteredConvenios] = useState(null);
  const [activeSearch, setActiveSearch] = useState(false);
  const [search, setSearch] = useState(null);

  const input = useRef(null);

  useEffect(() => {
    getInsurances().then(res => setConvenios(res));
  }, []);

  useEffect(() => {
    // filtra aqui e seta o filteredConvenios
    if (convenios && !filteredConvenios) {
      setFilteredConvenios(convenios);
    }
    if (search !== null) {
      const regex = new RegExp(`${slugify(search)}`, "gi") || null;
      setFilteredConvenios(
        convenios.filter(convenio => slugify(convenio.title).match(regex))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convenios, search]);

  useEffect(() => {
    if (activeSearch) {
      input.current.focus();
    }
  }, [activeSearch]);

  return (
    <Wrap id="convenios" className="light-grey">
      <SectionTitle>
        Nossos <strong>Convênios</strong>
      </SectionTitle>
      <SearchInput>
        {!activeSearch && (
          <button type="button" onClick={() => setActiveSearch(true)}>
            Clique aqui e veja se atendemos o seu plano
          </button>
        )}
        {activeSearch && (
          <div className="input_wrap">
            <label for="search_insurance_input">
              <SearchIcon />
            </label>
            <input
              type="text"
              id="search_insurance_input"
              ref={input}
              placeholder="Digite o nome do seu plano"
              onChange={e => setSearch(e.target.value)}
            />
          </div>
        )}
      </SearchInput>
      {filteredConvenios ? (
        <StyledSlider
          rows={2}
          slidesPerRow={4}
          autoplay={true}
          pauseOnHover={true}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesPerRow: 3,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesPerRow: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesPerRow: 1,
              },
            },
          ]}
        >
          {filteredConvenios.map(convenio => (
            <div className="insurance_card">
              <img
                src={convenio.image.url}
                alt={`Logo do convênio ${convenio.title}`}
              />
              <span>{convenio.title}</span>
            </div>
          ))}
        </StyledSlider>
      ) : null}
    </Wrap>
  );
};
