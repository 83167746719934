import { Button } from "@material-ui/core";
import Carousel from "nuka-carousel";
import styled from "styled-components";

export const StyledCarousel = styled(Carousel)`
  margin-top: 86px;

  .slider-control-bottomcenter {
    svg {
      margin: 7px;
      circle {
        fill: transparent;
        stroke: #fff;
        transition: 0.4s all;
      }
      &.active {
        circle {
          fill: #fff;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .slider-slide {
    overflow: hidden;
  }
`;

export const Control = styled(Button)`
  &.MuiButton-root {
    margin: 10px;
  }
  &#next {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Slide = styled.div`
  height: calc(100vh - 86px);
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    height: calc(100vh - 0px);
  }

  @media screen and (max-width: 350px) {
    height: calc(100vh + 100px);
  }

  img {
    min-width: 100%;

    @media only screen and (max-width: 1410px) {
      min-height: 100%;
      min-width: unset;
    }
    @media only screen and (max-width: 768px) {
      &#img2 {
        margin-right: -50%;
      }
    }
    @media only screen and (max-width: 600px) {
      min-height: unset;
      height: calc(75vh - 86px);
      margin-left: -15%;

      &#img2 {
        margin-right: unset;
      }
    }
    @media only screen and (max-width: 450px) {
      &#img1 {
        margin-left: -30%;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 562.26px;
  height: 442.76px;
  background: ${props => props.theme.primaryGreen};
  border-radius: 0 45px 0 0;
  transform: rotate(-10deg) skewY(6deg);
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  position: absolute;
  left: -65px;
  bottom: -60px;
  /* padding: 90px 80px 0px 80px; */
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  z-index: 0;

  @media only screen and (max-width: 1024px) {
    transform: rotate(-10deg) skewY(6deg) scale(0.9);
    left: -85px;
    bottom: -80px;
  }

  @media only screen and (max-width: 600px) {
    transform: rotate(8deg);
    border-radius: 0;
    left: -70px;
    bottom: -50px;
    width: 700px;
    height: 440px;
  }

  @media screen and (max-width: 350px) {
    bottom: -50px;
    width: 700px;
    height: 480px;
  }

  .content-text {
    transform: rotate(10deg) skewY(-6deg);
    width: 370px;
    position: absolute;
    top: 60px;
    right: 0;

    @media only screen and (max-width: 600px) {
      transform: rotate(-8deg);
      top: 25px;
      left: 32px;
      width: 100vw;
      padding: 0 30px 0 40px;
      box-sizing: border-box;
    }

    h1 {
      margin: 19px 0;
    }
    p {
      color: #fff;
    }

    .MuiButton-root {
      margin-top: 25px;
      @media only screen and (max-width: 600px) {
        width: fit-content;
        align-self: center;
      }

      .MuiButton-label {
        transform: none;
      }
    }
  }
`;
