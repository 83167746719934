import React from "react";

const SvgMobileHeaderBack = props => (
  <svg width={84} height={84} fill="none" {...props}>
    <path
      d="M84 0v72.909l-53.933 5.766c-9.554 1.022-18.226-5.641-19.7-15.136L.5 0H84z"
      fill="#192E59"
    />
  </svg>
);

export default SvgMobileHeaderBack;
