import React from "react";

const SvgX = props => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
      d="M2 2l12 12m0-12L2 14"
      stroke="#192E59"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgX;
