import { Button } from "@material-ui/core";
import Carousel from "nuka-carousel";
import styled from "styled-components";

export const Control = styled(Button)`
  opacity: 0;
  &.MuiButton-root {
    margin: 10px;
  }
  &#next {
    svg {
      transform: rotate(180deg);
    }
  }
  .MuiButton-label {
    svg > path {
      stroke: #192e59;
    }
  }
`;

export const StyledCarousel = styled(Carousel)`
  width: 70%;

  .slider-list {
  }

  .slider-slide {
    max-width: 372px;
    .MuiPaper-root {
      margin: auto;
    }

    @media only screen and (max-width: 600px) {
      max-width: 100%;
    }
  }
  &:hover {
    ${Control} {
      opacity: 1;
    }
  }

  .paging-item {
    margin: 5px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 10px;
    height: 12px;
    width: 12px;

    &.active {
      background: #192e59 !important;
    }

    button {
      position: absolute;
      width: 12px;
      height: 12px;
      opacity: 0 !important;
    }
    svg {
      display: none;
    }
  }
`;
