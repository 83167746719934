import axios from "axios";

const getServicos = () => {
  return axios
    .get(`${process.env.REACT_APP_API_HOST}/wp/v2/posts?categories=3`)
    .then(res => {
      console.log("getServicos: ", res);
      return res;
    })
    .catch(err => {
      console.log("Ocorreu um erro: ", err);
    });
};

export default getServicos;
