import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #fff;
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 86px;
  background: #192e59;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    justify-content: flex-end;
    padding-right: 50px;
  }

  @media screen and (max-width: 400px) {
    justify-content: flex-end;
    padding-right: 25px;
  }

  .MuiButton-root {
    position: absolute;
    display: flex;
    align-items: center;
    left: 34px;
    @media screen and (max-width: 400px) {
      left: 20px;
    }

    .MuiButton-label {
      text-transform: none;
      color: #fff;
      font-size: 25px;
      line-height: 29px;
      svg {
        margin-right: 10px;
      }
    }
  }
`;

export const Logo = styled.img`
  height: fit-content;
`;

export const Main = styled.div`
  min-height: calc(100vh - 86px);
  width: 100%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  align-items: center;
  padding: 70px 90px 100px 90px;

  @media screen and (max-width: 768px) {
    padding: 35px 20px;
  }
`;
