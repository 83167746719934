import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  
  @import url('https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Arvo:400,700&display=swap');
  
  *{
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
  }
  html, body, #root{
    height: 100%;
    background: #eee;
  }

  h1{
    font-family: 'Arvo', serif;
    font-size: 33px;
    line-height: 39px;
    color: ${props => props.theme.primaryBlue};
  }
  
  h2{
    color: ${props => props.theme.primaryBlue};
  }

  p{
    font-size: 16px;
    line-height: 24px;
  }
  
  a{
    text-decoration: none;
  }
`;

export const Container = styled.div`
  /* height: 100%; */
`;
