import Blog from "./pages/Blog";
import TelaInicial from "./pages/Home";

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={TelaInicial} />
      <Redirect exact from="/blog" to="/blog/list" />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/blog/list" component={Blog} />
      <Route exact path="/blog/post" component={Blog} />
    </Switch>
  );
};

export default Routes;
