import { StyledCarousel, Control } from "./styles";

import Arrow from "../../icons/CarouselArrow";

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import windowSize from "react-window-size";

const CardsCarousel = ({ windowWidth, windowSize, slidesToShow, children }) => {
  /**
   * Carousel que exibe cards em home
   */

  const [slidePosition, setSlidePosition] = useState(0);

  useEffect(() => console.log(slidePosition), [slidePosition]);

  return (
    <StyledCarousel
      swiping={true}
      dragging={windowWidth <= 768}
      autoplay={true}
      wrapAround={true}
      renderCenterLeftControls={({ currentSlide, previousSlide }) => (
        <Control
          id="prev"
          onClick={() => [previousSlide(), setSlidePosition(slidePosition - 1)]}
        >
          <Arrow />
        </Control>
      )}
      renderCenterRightControls={({ slideCount, currentSlide, nextSlide }) => (
        <Control
          id="next"
          onClick={() => [nextSlide(), setSlidePosition(slidePosition + 1)]}
        >
          <Arrow />
        </Control>
      )}
      slidesToShow={
        windowWidth <= 600 ? 1 : windowWidth <= 768 ? 2 : slidesToShow
      }
      slidesToScroll={windowWidth <= 768 ? 1 : 2}
      cellSpacing={20}
      framePadding="64px 6.5%"
    >
      {children}
    </StyledCarousel>
  );
};

CardsCarousel.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  windowSize: PropTypes.number.isRequired,
  slidesToShow: PropTypes.number,
  children: PropTypes.element,
};

export default windowSize(CardsCarousel);
