import {
  Container,
  TextInput,
  Row,
  InputArea,
  BtnRow,
  Warning,
} from "./styles";

import postContato from "../../functions/postContato";
import WhatsAppIcon from "../../icons/Whatsapp";
import Button from "../Button";
import Dialog from "../Dialog";

import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

//icons

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Preencha os campos obrigatórios."),
  email: Yup.string().required("Preencha os campos obrigatórios"),
  subject: Yup.string().required("Preencha os campos obrigatórios"),
  msg: Yup.string(),
});

const ContactForm = () => {
  /**
   * Formulário de contato da página /home
   */

  const [sent, setSent] = useState(false);

  return (
    <Container>
      <Formik
        initialValues={{
          name: "",
          email: "",
          subject: "",
          msg: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          let formData = new FormData();
          formData.append("name", values.name);
          formData.append("email", values.email);
          formData.append("subject", values.subject);
          formData.append("message", values.msg);
          let post = await postContato(formData);

          if (post.status === 200) {
            console.log(formData);
            setSent(true);
          }

          setTimeout(() => [setSent(false), actions.resetForm()], 5000);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          isSubmitting,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <InputArea>
                <label htmlFor="name">
                  Nome <span>*</span>
                </label>
                <TextInput
                  variant="outlined"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  name="name"
                />
              </InputArea>

              <InputArea>
                <label htmlFor="email">
                  Email <span>*</span>
                </label>
                <TextInput
                  variant="outlined"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                />
              </InputArea>
            </Row>

            <InputArea>
              <label htmlFor="subject">
                Assunto <span>*</span>
              </label>
              <TextInput
                variant="outlined"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.subject}
                name="subject"
              />
            </InputArea>

            <InputArea>
              <label htmlFor="msg">Mensagem</label>
              <TextInput
                variant="outlined"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.msg}
                name="msg"
                multiline
                rows="8"
              />
            </InputArea>

            <BtnRow>
              <Button
                disabled={
                  !values.name ||
                  !values.email ||
                  !values.subject ||
                  isSubmitting
                }
              >
                Enviar email
              </Button>

              <a
                href={
                  "https://api.whatsapp.com/send?phone=557121013553&text=" +
                  `${encodeURIComponent(
                    `*${values.name}*\n${values.email}\n${values.subject}\n\n${values.msg}`
                  )}
              `
                }
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                <Button
                  type="button"
                  green
                  onClick={() => {
                    console.log("api whatsapp");
                  }}
                >
                  <WhatsAppIcon /> Enviar via Whatsapp
                </Button>
              </a>
            </BtnRow>

            <Dialog
              open={sent}
              handleClose={() => [setSent(false), resetForm()]}
            >
              Sua mensagem foi enviada com sucesso!
            </Dialog>

            {/* {sent ? 
            <Warning>Sua mensagem foi enviada!</Warning>
            :null} */}
            {errors ? <Warning>{errors.name}</Warning> : null}
            {/* {console.log(isSubmitting)} */}
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default ContactForm;
