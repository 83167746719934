import styled from "styled-components";

export const Container = styled.div`
  margin-top: auto;
  width: 100%;
  min-height: 315px;

  a {
    color: ${({ theme }) => theme.primaryBlue};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Info = styled.div`
  position: relative;
  min-height: 243px;
  background: ${props => props.theme.primaryGreen};
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;

  span {
    color: #fff;
    text-align: center;
  }

  #facebook-icon {
    position: absolute;
    top: 40px;
    right: 53px;
    @media screen and (max-width: 425px) {
      right: 25px;
    }
  }

  #instagram-icon {
    position: absolute;
    top: 83px;
    right: 47px;
    @media screen and (max-width: 425px) {
      right: 19px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
`;

export const Line = styled.div`
  border-radius: 3px;
  width: 3px;
  height: 68px;
  background-color: #00a08b;
  margin: auto 0;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const InfoBlock = styled.div`
  max-width: 312px;
  padding: 47px;
  box-sizing: border-box;
  display: flex;
  /* flex-flow: column; */
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding: 27px;
    justify-content: center;
  }

  p {
    color: ${props => props.theme.primaryBlue};
    /* font-weight: 700; */
    display: flex;
    align-items: center;
    line-height: 26px;

    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }

  svg {
    margin-right: 10px;
    &#location {
      min-width: 16px;
    }
    path {
      fill: ${props => props.theme.primaryBlue};
    }
  }
`;

export const Rights = styled.div`
  background: #00a08b;
  height: 72px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
