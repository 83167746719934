import axios from "axios";

const getArtigos = async params => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_HOST}/wp/v2/posts?categories=3,4${params}`
    )
    .then(res => {
      console.log("getArtigos: ", res);
      return res;
    })
    .catch(err => {
      console.log("Ocorreu um erro: ", err);
    });
};

export default getArtigos;
