import React from "react";

const SvgClock = props => (
  <svg width={21} height={21} fill="none" {...props}>
    <path
      d="M10.5.328A10.17 10.17 0 00.328 10.5 10.17 10.17 0 0010.5 20.672 10.17 10.17 0 0020.672 10.5 10.17 10.17 0 0010.5.328zm0 18.375A8.2 8.2 0 012.297 10.5 8.2 8.2 0 0110.5 2.297a8.2 8.2 0 018.203 8.203 8.2 8.2 0 01-8.203 8.203zm2.535-4.282l-3.482-2.53a.495.495 0 01-.201-.398V4.758c0-.27.221-.492.492-.492h1.312c.271 0 .492.221.492.492v5.812l2.74 1.993c.222.16.267.468.107.69l-.771 1.062a.496.496 0 01-.69.106z"
      fill="#192E59"
    />
  </svg>
);

export default SvgClock;
