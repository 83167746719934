import img from "./confirm_icon_sprite.png";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "styled-components";

export const DialogContainer = styled(Dialog)`
  .MuiDialog-paper {
    width: 445px;
    border-radius: 10px;
  }
`;

export const Content = styled(DialogContent)`
  &.MuiDialogContent-root:first-child {
    padding: 32px 63px;
    display: flex;
    flex-flow: column;
    align-items: center;

    .x-btn {
      position: absolute;
      top: 18px;
      right: 18px;

      &.MuiButton-root {
        min-width: unset;
        svg {
          margin: 0;
        }
      }
    }

    @keyframes anim {
      0% {
        background-position-x: 0%;
      }
      10% {
        background-position-x: 12.5%;
      }
      20% {
        background-position-x: 25%;
      }
      30% {
        background-position-x: 37.5%;
      }
      40% {
        background-position-x: 50%;
      }
      50% {
        background-position-x: 62.5%;
      }
      60% {
        background-position-x: 75%;
      }
      70% {
        background-position-x: 87.5%;
      }
      80% {
        background-position-x: 100%;
      }
      100% {
        background-position-x: 100%;
      }
    }

    #animation {
      background-image: url(${img});
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: 0px;
      animation: anim 0.5s infinite;
      animation-timing-function: steps(1, end);
      width: 102px;
      height: 102px;
      &.static-true {
        animation: none;
        background-position-x: 100%;
      }
    }

    svg {
      margin-bottom: 25px;
    }

    p {
      text-align: center;
      font-size: 25px;
      line-height: 35px;
      color: ${props => props.theme.primaryBlue};
    }
  }
`;
