import axios from "axios";

const commonConfig = {
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: process.env.REACT_APP_REQUEST_TIMEOUT || 10000,
};

const wordPressApiRequest = axios.create({
  ...commonConfig,
});

wordPressApiRequest.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    let reducedResponse;
    if (response.data) {
      reducedResponse = response.data.reduce((acc, cur) => {
        return [
          ...acc,
          {
            ...cur,
            title: cur.title.rendered,
            content: cur.content.rendered,
            excerpt: cur.excerpt.rendered,
            image: cur.better_featured_image
              ? {
                  url: cur.better_featured_image.source_url,
                  altText: cur.better_featured_image.alt_text,
                  thumbnailUrl:
                    cur.better_featured_image.media_details.sizes.thumbnail
                      .source_url,
                }
              : null,
          },
        ];
      }, []);
      reducedResponse.forEach(elem => {
        delete elem.guid;
        delete elem.author;
        delete elem.featured_media;
        delete elem.comment_status;
        delete elem.ping_status;
        delete elem.sticky;
        delete elem.template;
        delete elem.link;
        delete elem.type;
        delete elem.format;
        delete elem.better_featured_image;
        delete elem._links;
        delete elem.date;
        delete elem.modified;
      });
    }
    return reducedResponse || null;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("[Axios Request Error] Data:", error.response.data);
      console.error("[Axios Request Error] Status:", error.response.status);
      console.error("[Axios Request Error] Headers:", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error("[Axios Request Error] Request:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("[Axios Request Error] Message:", error.message);
    }
    console.error("[Axios Request Error] Config:", error.config);
    return Promise.reject(error);
  }
);

export default () => {
  /* Busca posts das seguintes categorias
   * 10: Convênios
   */
  return wordPressApiRequest.get("/wp/v2/posts?categories=10");
};
