import React from "react";

const SvgInstagram = props => (
  <svg width={22} height={22} fill="none" {...props}>
    <path
      d="M11.005 5.61c-3.123 0-5.642 2.404-5.642 5.385 0 2.982 2.519 5.386 5.642 5.386s5.642-2.405 5.642-5.386c0-2.981-2.519-5.386-5.642-5.386zm0 8.887c-2.018 0-3.668-1.57-3.668-3.502 0-1.931 1.645-3.501 3.668-3.501s3.668 1.57 3.668 3.501c0 1.932-1.65 3.502-3.668 3.502zm7.19-9.108c0 .698-.59 1.256-1.317 1.256-.732 0-1.316-.562-1.316-1.256s.59-1.256 1.316-1.256c.727 0 1.316.562 1.316 1.256zm3.736 1.275c-.083-1.683-.486-3.173-1.777-4.402C18.867 1.034 17.305.65 15.542.566c-1.816-.099-7.263-.099-9.08 0-1.758.08-3.32.464-4.61 1.692C.56 3.486.161 4.976.073 6.659c-.103 1.735-.103 6.933 0 8.668.083 1.682.486 3.173 1.777 4.401 1.292 1.228 2.849 1.612 4.612 1.697 1.816.098 7.263.098 9.08 0 1.762-.08 3.324-.464 4.61-1.697 1.287-1.228 1.69-2.719 1.778-4.402.103-1.734.103-6.928 0-8.662zm-2.347 10.523c-.383.92-1.125 1.627-2.092 1.997-1.449.549-4.886.422-6.487.422s-5.043.122-6.487-.422a3.634 3.634 0 01-2.092-1.997c-.575-1.382-.442-4.664-.442-6.192 0-1.528-.128-4.814.442-6.192.383-.919 1.124-1.627 2.092-1.997 1.449-.548 4.886-.422 6.487-.422 1.6 0 5.043-.122 6.487.422a3.634 3.634 0 012.092 1.997c.575 1.383.442 4.664.442 6.192 0 1.528.133 4.814-.442 6.192z"
      fill="#192E59"
    />
  </svg>
);

export default SvgInstagram;
