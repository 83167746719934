import { Container, Info, Rights, InfoBlock, Row, Line } from "./styles";

import Clock from "../../icons/Clock";
import Facebook from "../../icons/Facebook";
import Instagram from "../../icons/Instagram";
import LocationPin from "../../icons/LocationPin";
import Phone from "../../icons/Phone";
import WhatsApp from "../../icons/Whatsapp";

import React from "react";

const Footer = props => {
  return (
    <Container>
      <Info>
        <Row>
          <InfoBlock>
            <div>
              <p>
                <Phone />
                (71) 2101-3553
              </p>
              <p>
                <WhatsApp />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wa.me/557121013553"
                  id="whatsapp"
                >
                  (71) 2101-3553
                </a>
              </p>
            </div>
          </InfoBlock>
          <Line />
          <InfoBlock>
            <LocationPin id="location" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://goo.gl/maps/zTyp848vGzww3CFA8"
            >
              <p>Rua Barão de Loreto, 75 Graça - Salvador/BA </p>
            </a>
          </InfoBlock>
          <Line />
          <InfoBlock>
            <Clock />
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/search?q=lithocenter+hospital+dia"
              >
                <p>Seg-Sex : 7h ás 17h</p>
              </a>
            </div>
          </InfoBlock>
        </Row>
        <span>Responsável Técnico: Dr. Modesto Jacobino CRM 3987/BA </span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/LithocenterHospitalDia/"
          id="facebook-icon"
        >
          <Facebook />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/lithocenter_/"
          id="instagram-icon"
        >
          <Instagram />
        </a>
      </Info>
      <Rights>
        <span>Filipe Lopes © 2019 - Todos os direitos reservados</span>
      </Rights>
    </Container>
  );
};

export default Footer;
