import LogoIcon from "../../icons/LithocenterLogo";
import RectangleHeader from "../../icons/RectangleHeader";

import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 2;
  background: #fff;
  height: 86px;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 0 90px;
  justify-content: space-between;

  @media only screen and (max-width: 1024px) {
    padding: 0 50px;
  }

  @media only screen and (max-width: 900px) {
    padding: 0 30px;
  }

  &.hide {
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      overflow: visible;
    }
  }

  .phone {
    background: ${({ theme }) => theme.primaryGreen};
    position: absolute;
    right: 570px;
    z-index: -1;
    padding: 10px 70px 10px 30px;
    border-radius: 28px 0 0 28px;

    @media only screen and (max-width: 768px) {
      right: 120px;
      width: 50px;
      height: 50px;
      padding: 12px 10px 0 13px;
      box-sizing: border-box;
      border-radius: 50%;
      span {
        display: none;
      }
      a.number {
        display: none;
      }
      a.icon {
        display: block;
      }
    }

    span {
      position: absolute;
      top: -15px;
      font-size: 12px;
      line-height: 15px;
    }

    .icon {
      display: none;
    }

    a {
      color: white;
      font-size: 26px;
      line-height: 26px;
    }
  }
`;

export const Logo = styled(LogoIcon)`
  height: 56px;
  width: auto;

  path {
    fill: #666666;
  }
`;

export const Rectangle = styled(RectangleHeader)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;

  @media only screen and (max-width: 1024px) {
    width: 60%;
  }

  @media only screen and (max-width: 980px) {
    width: 68%;
  }

  @media only screen and (max-width: 768px) {
    width: 74%;
  }
`;

export const Navbar = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 3;

  .MuiButton-root {
    margin: 3px;
    .MuiButton-label {
      color: #fff;
      font-size: 16px;
      line-height: 19px;
    }

    &#btn-artigos {
      border: solid 1px ${props => props.theme.primaryGreen};
    }
  }
`;

export const Paper = styled.div`
  background: ${props => props.theme.primaryBlue};
  display: flex;
  flex-flow: column;
  position: absolute;
  top: -1px;
  right: 0;
  height: 308px;
  width: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  z-index: 0;
  padding-top: 100px;

  &.open {
    width: 211px;
  }

  .MuiButton-root {
    padding: 24px 40px;
    width: 100%;
    .MuiButton-label {
      text-align: center;
      color: #fff;
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const MobileMenu = styled.div`
  > svg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    transform: scale(1.6);
  }

  > .MuiButton-root {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    min-width: fit-content;
    width: 95px;
    height: 95px;
  }
`;
