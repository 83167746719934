import axios from "axios";

export default () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/wp/v2/banners`)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
