import React from "react";

const SvgCarouselArrow = props => (
  <svg width={19} height={58} fill="none" {...props}>
    <path
      d="M17 2.5l-15 26 15 27"
      stroke="#fff"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCarouselArrow;
