import { BlueBtn, OutlinedBtn, TransparentBtn } from "./styles";

import React from "react";

const PrimaryButton = ({
  onClick,
  children,
  outlined,
  green,
  type,
  transparent,
  ...props
}) => {
  /**
   * Botão primário do sistema, possui versões outlined e normal
   */
  if (outlined) {
    return (
      <OutlinedBtn type={type ? type : ""} onClick={onClick} {...props}>
        {children}
      </OutlinedBtn>
    );
  }

  if (transparent) {
    return (
      <TransparentBtn type={type ? type : ""} onClick={onClick} {...props}>
        {children}
      </TransparentBtn>
    );
  } else {
    return (
      <BlueBtn
        type={type ? type : ""}
        className={green ? "green" : ""}
        onClick={onClick}
        {...props}
      >
        {children}
      </BlueBtn>
    );
  }
};

export default PrimaryButton;
