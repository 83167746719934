import { Button } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;

  .MuiFormControl-root {
    margin: 60px 0;
    @media screen and (max-width: 600px) {
      margin: 30px 0 40px;
    }
  }
`;

export const FilterArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 35px;
  box-sizing: border-box;

  h2 {
    font-size: 25px;
    line-height: 29px;
    font-family: Raleway, sans-serif;
    margin-right: 10px;
  }
`;

export const FilterBtn = styled(Button)`
  .MuiButton-label {
    text-transform: none;
    font-size: 22px;
    line-height: 28px;
    color: #192e59;
    font-family: Raleway, sans-serif;
  }
`;

export const CardsArea = styled.div`
  /* margin-top: 70px; */
  /* margin-bottom: 70px; */
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(372px, 1fr));
  justify-items: center;
  /* grid-row-gap: 100px; */
  /* grid-column-gap: 50px; */

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  .MuiCard-root {
    margin: 50px 35px;

    @media screen and (max-width: 500px) {
      margin: 15px 0;
    }
  }
`;
