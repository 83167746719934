import React from "react";

const SvgDefaultImg = props => (
  <svg width={114} height={112} fill="none" {...props}>
    <path
      d="M101.56 9.98C100.225 4.338 95.147.124 89.1.124H19.242C8.631.123 0 8.755 0 19.364v69.86c0 6.938 5.548 12.601 12.44 12.795 1.335 5.644 6.413 9.858 12.46 9.858h76.295c7.061 0 12.805-5.744 12.805-12.805V22.776c0-6.938-5.548-12.6-12.44-12.795zm7.987 89.093c0 4.605-3.747 8.351-8.352 8.351H24.899c-3.565 0-6.613-2.245-7.81-5.395a8.31 8.31 0 01-.542-2.956V87.26l25.545-43.312a4.163 4.163 0 013.686-2.064 4.15 4.15 0 013.614 2.19L60.39 64.268l15.987 29.355a2.226 2.226 0 103.91-2.13L75.1 81.968a9.184 9.184 0 006.302-2.113l10.013-8.306a4.213 4.213 0 015.515.136l.523.48 4.453 4.081 7.641 7.005v15.822zm0-21.863l-7.641-7.005-4.453-3.39c-2.902-1.214-6.351-.792-8.881 1.306l-10.013 8.307a4.752 4.752 0 01-3.945 1.013 4.753 4.753 0 01-3.288-2.403l-7.025-12.9-3.923-7.203-7.075-12.992c-1.51-2.77-4.294-4.458-7.449-4.511-3.154-.05-5.995 1.536-7.598 4.254L16.547 78.494V22.776c0-4.605 3.747-8.351 8.352-8.351h13.758a2.227 2.227 0 000-4.454H24.9c-7.06 0-12.805 5.745-12.805 12.805V97.542c-4.273-.361-7.64-3.953-7.64-8.318v-69.86c0-8.154 6.633-14.788 14.787-14.788h69.86c3.564 0 6.613 2.245 7.81 5.396H56.47a2.226 2.226 0 100 4.453h40.983l4.453.032c4.273.362 7.641 3.954 7.641 8.32V77.21z"
      fill="#BDBDBD"
    />
  </svg>
);

export default SvgDefaultImg;
