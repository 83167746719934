import { DialogContainer, Content } from "./styles";

import X from "../../icons/X";

import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";

const AlertDialog = ({ children, open, handleClose }) => {
  const [staticImg, setStaticImg] = useState(false);

  if (open) {
    setTimeout(() => setStaticImg(true), 500);
  }
  if (!open && staticImg) {
    setTimeout(() => setStaticImg(false), 500);
  }

  return (
    <DialogContainer
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Content>
        <Button onClick={handleClose} className="x-btn">
          <X />
        </Button>
        <div className={`static-${staticImg}`} id="animation" />
        <p>{children}</p>
      </Content>
    </DialogContainer>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.element,
};

export default AlertDialog;
