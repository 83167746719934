import axios from "axios";

const getTags = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_HOST}/wp/v2/tags`)
    .then(res => {
      console.log("getTags: ", res);
      return res;
    })
    .catch(err => {
      console.log("Ocorreu um erro: ", err);
    });
};

export default getTags;
