import {
  Container,
  Logo,
  Rectangle,
  Navbar,
  MobileMenu,
  Paper,
} from "./styles";

import scrollWidthOffset from "../../functions/scrollOfset";
import MobileHeaderBack from "../../icons/MobileHeaderBack";

import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import HamburgerMenu from "react-hamburger-menu";
import { HashLink as Link } from "react-router-hash-link";
import windowSize from "react-window-size";

const Header = ({ headerVisible, windowWidth }) => {
  /**
   * Header da página /home
   */

  const [openMenu, setOpenMenu] = React.useState(false);

  const toggleMenu = event => {
    if (!openMenu) setOpenMenu(true);
    else setOpenMenu(false);
  };

  return (
    <Container id="header" className={!headerVisible ? "hide" : ""}>
      <Link scroll={el => scrollWidthOffset(el)} to="/#top">
        <Logo />
      </Link>
      <div className="phone">
        <a href="tel:+557121013553" className="icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.0316152 1.40763L0.0316153 4.01173C0.172378 13.3021 12.0668 23.9296 20.0199 24L22.624 24C23.3278 24 23.9612 23.4369 23.9612 22.6628L23.8909 17.3842C23.8909 16.6804 23.2574 16.0469 22.5536 16.0469L16.7824 15.9062L13.9671 19.2141C12.1372 19.2141 4.74716 11.824 4.67678 9.92375L7.98469 6.89736L7.84393 1.33724C7.84393 0.633428 7.2105 -3.13799e-07 6.50669 -2.83034e-07L1.36886 0.0703792C0.594665 0.0703792 0.0316152 0.63343 0.0316152 1.40763Z"
              fill="white"
            />
          </svg>
        </a>
        <span>Agende sua consulta:</span>
        <a href="tel:+557121013553" className="number">
          (71) 2101-3553
        </a>
      </div>
      {windowWidth > 768 ? (
        <>
          <Rectangle />
          <Navbar>
            <Link scroll={el => scrollWidthOffset(el)} to="/#especialidades">
              <Button>Serviços</Button>
            </Link>

            <Link scroll={el => scrollWidthOffset(el)} to="/#localizacao">
              <Button>Localização</Button>
            </Link>

            <Link scroll={el => scrollWidthOffset(el)} to="/#contato">
              <Button>Contato</Button>
            </Link>

            <Link scroll={el => scrollWidthOffset(el)} to="/#artigos">
              <Button id="btn-artigos">Artigos</Button>
            </Link>
          </Navbar>
        </>
      ) : (
        <MobileMenu>
          <MobileHeaderBack />
          <Button onClick={toggleMenu}>
            <HamburgerMenu
              isOpen={Boolean(openMenu)}
              menuClicked={toggleMenu}
              width={30}
              height={24}
              strokeWidth={2}
              rotate={0}
              color="white"
              borderRadius={0}
              animationDuration={0.5}
            />
          </Button>
          <Paper className={`${openMenu ? "open" : ""}`}>
            <Link scroll={el => scrollWidthOffset(el)} to="/#especialidades">
              <Button>Especialidades</Button>
            </Link>

            <Link scroll={el => scrollWidthOffset(el)} to="/#localizacao">
              <Button>Localização</Button>
            </Link>

            <Link scroll={el => scrollWidthOffset(el)} to="/#contato">
              <Button>Contato</Button>
            </Link>

            <Link scroll={el => scrollWidthOffset(el)} to="/#artigos">
              <Button id="btn-artigos">Artigos</Button>
            </Link>
          </Paper>
        </MobileMenu>
      )}
    </Container>
  );
};

Header.propTypes = {
  headerVisible: PropTypes.bool,
  windowWidth: PropTypes.number,
};

export default windowSize(Header);
