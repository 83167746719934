import axios from "axios";

const getArtigosById = async id => {
  return await axios
    .get(`${process.env.REACT_APP_API_HOST}/wp/v2/posts/${id}`)
    .then(res => {
      console.log("getArtigosById: ", res);
      return res;
    })
    .catch(err => {
      console.log("Ocorreu um erro: ", err);
    });
};

export default getArtigosById;
