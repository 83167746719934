import React from "react";

const SvgRectangleHeader = props => (
  <svg width={654} height={199} fill="none" {...props}>
    <path
      d="M662.5 85.5L86.37 115.972c-22.1 1.169-41.77-13.905-46.387-35.548L0-107l679.984-38.733L662.5 85.501z"
      fill="#192E59"
    />
  </svg>
);

export default SvgRectangleHeader;
