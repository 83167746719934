import { PrimaryCard, SecondaryCard, ArticleCard, GreyCard } from "./styles";

import extractDate from "../../functions/extractDate";
import Arrow from "../../icons/Arrow";
import DefaultImg from "../../icons/DefaultImg";
import Button from "../Button";

import { CardActions, CardContent } from "@material-ui/core";
import React from "react";
import Truncate from "react-truncate";

export default function SimpleCard({
  type,
  imgUrl,
  title,
  excerpt,
  btnContent,
  btnAction,
  date,
}) {
  /**
   * prop 'type' necessária.
   * Componente de cards, possui tipos:
   *
   * 1 = Background cinza, button outlined branco com hover azul.
   *     Utilizado em seção 'Serviços', em Home.
   * 2 = Imagem, background cinza, e hover mostra excerpt com fundo azul,
   *     Utilizado em seção 'Artigos', em Home.
   * 3 = Imagem, background branco. Utilizado em listagem de Posts, em Blog.
   * 4 = Imagem, background cinza, button transparente. Utilizado em seção
   *     'Artigos Relacionados', em tela de Post.
   */

  if (type === 1) {
    return (
      <PrimaryCard>
        <CardContent>
          <h1>{title}</h1>
          <p>{excerpt}</p>
        </CardContent>
        <CardActions>
          <Button onClick={btnAction} outlined>
            {btnContent}
            <Arrow />
          </Button>
        </CardActions>
      </PrimaryCard>
    );
  }

  if (type === 2) {
    return (
      <SecondaryCard>
        <div className="img-container">
          {imgUrl ? <img src={imgUrl} alt="imagem do card" /> : <DefaultImg />}
        </div>
        <CardContent>
          <h1>
            <Truncate lines={3} ellipsis={<span>...</span>}>
              {title}
            </Truncate>
          </h1>
          <p>
            <Truncate lines={4} ellipsis={<span>...</span>}>
              {excerpt}
            </Truncate>
          </p>
          <Button onClick={btnAction} outlined>
            {btnContent}
            <Arrow />
          </Button>
        </CardContent>
      </SecondaryCard>
    );
  }
  if (type === 3) {
    return (
      <ArticleCard>
        <div className="img-container">
          {imgUrl ? <img src={imgUrl} alt="imagem do card" /> : <DefaultImg />}
        </div>
        <CardContent>
          <span id="date">{extractDate(date)}</span>
          <h1>{title}</h1>
          <p>
            <Truncate lines={4} ellipsis={<span>...</span>}>
              {excerpt}
            </Truncate>
          </p>
          <Button onClick={btnAction} outlined>
            {btnContent}
            <Arrow />
          </Button>
        </CardContent>
      </ArticleCard>
    );
  }

  if (type === 4) {
    return (
      <GreyCard>
        <div className="img-container">
          {imgUrl ? <img src={imgUrl} alt="imagem do card" /> : <DefaultImg />}
        </div>
        <CardContent>
          <span id="date">{date}</span>
          <h1>{title}</h1>
          <p>
            <Truncate lines={3} ellipsis={<span>...</span>}>
              {excerpt}
            </Truncate>
          </p>
          <Button onClick={btnAction} transparent>
            {btnContent}
            <Arrow />
          </Button>
        </CardContent>
      </GreyCard>
    );
  }
}
