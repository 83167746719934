import axios from "axios";

const postContato = formData => {
  return axios
    .post(`${process.env.REACT_APP_API_HOST}/smtp/v1/contato`, formData)
    .then(res => {
      console.log("postContato: ", res);
      return res;
    })
    .catch(err => {
      console.log("Ocorreu um erro: ", err);
    });
};

export default postContato;
