import Card from "@material-ui/core/Card";
import styled from "styled-components";

export const PrimaryCard = styled(Card)`
  /* width: 350px; */
  &.MuiPaper-root {
    box-shadow: none;
    /* min-height: 330px;
  min-height: fit-content; */
    height: 350px;
    background: #eee;
    border-radius: 10px;
    padding: 25px 36px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;

    .MuiCardContent-root {
      img {
        width: 100%;
      }
    }

    .MuiCardActions-root {
      margin-top: auto;
    }
  }

  h1 {
    font-size: 25px;
    line-height: 29px;
    font-weight: normal;
    margin: 0;
    text-align: start;
    margin-bottom: 24px;
  }

  .MuiButton-root {
    svg {
      margin-left: 10px;
    }
  }
`;

export const SecondaryCard = styled(Card)`
  /* width: 350px; */
  &.MuiPaper-root {
    box-shadow: none;
    height: calc(205px + 245px);
    background: #eee;
    border-radius: 10px;
    /* padding: 25px 36px; */
    box-sizing: border-box;
    overflow: hidden;

    .img-container {
      /* position: absolute; */
      background-color: #dfdfdf;
      padding: 0;
      height: 205px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }

      svg {
        transform: scale(0.9);
      }
    }
    .MuiCardContent-root {
      position: absolute;
      bottom: 0;
      height: 245px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0 0 10px 10px;
      padding: 36px 40px 40px 36px;
      transition: all 0.35s;
      h1 {
        font-size: 25px;
        line-height: 29px;
        font-weight: normal;
        margin: 0;
        text-align: start;
        margin-bottom: 10px;
      }

      p {
        transition: all 0.35s;
        opacity: 0;
        height: 0;
      }

      .MuiButton-root {
        transition: all 0.35s;
        position: absolute;
        bottom: 40px;
        svg {
          margin-left: 10px;
        }
      }
    }
    .MuiCardActions-root {
      padding-left: 36px;
    }
  }

  &:hover {
    &.MuiPaper-root {
      .MuiCardContent-root {
        height: 319px;
        background: #192e59;
        /* position: absolute; */
        h1 {
          color: #fff;
        }

        p {
          opacity: 1;
          height: fit-content;
          color: #fff;
        }

        .MuiButton-root {
          background: ${props => props.theme.primaryGreen};
          .MuiButton-label {
            color: #fff;
            svg > path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
`;

export const ArticleCard = styled(Card)`
  min-height: 450px;
  /* min-width: 300px; */
  max-width: 372px;

  &.MuiPaper-root {
    box-shadow: none;
  }

  .img-container {
    height: 179px;
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;

    img {
      width: 100%;
    }

    svg {
      transform: scale(0.8);
    }
  }

  .MuiCardContent-root {
    > span {
      font-family: Raleway;
      color: ${props => props.theme.lightGrey};
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */
      font-feature-settings: "pnum" on, "lnum" on, "liga" off;
    }

    h1 {
      font-size: 25px;
      line-height: 29px;
      margin: 10px 0;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      text-align: justify;
      color: #a0a0a0;
    }

    .MuiButton-root {
      margin-top: 24px;
      padding: 18px 20px;
      .MuiButton-label {
        svg {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const GreyCard = styled(Card)`
  min-height: 381px;
  min-width: 290px;
  max-width: 327px;

  &.MuiPaper-root {
    box-shadow: none;
    background: #f4f4f4;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .img-container {
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    background-color: #dfdfdf;

    img {
      width: 100%;
    }

    svg {
      transform: scale(0.7);
    }
  }

  .MuiCardContent-root {
    padding-bottom: 0;

    > span {
      font-family: Raleway;
      color: ${props => props.theme.lightGrey};
      font-size: 16px;
      line-height: 19px;
    }

    h1 {
      font-size: 25px;
      line-height: 29px;
      margin: 10px 0;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      text-align: justify;
      color: #a0a0a0;
    }

    .MuiButton-root {
      margin-top: 15px;
      .MuiButton-label {
        svg {
          margin-left: 10px;
        }
      }
    }
  }
`;
