import Footer from "./components/Footer";
import Routes from "./routes";
import { Container, GlobalStyles } from "./styles.App";
import { theme } from "./theme";

import React from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";
//theme
import { ThemeProvider } from "styled-components";

//styles
//components

ReactGA.initialize("UA-63307116-2");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Container>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <Routes />
          <Footer />
        </Router>
      </ThemeProvider>
    </Container>
  );
}

export default App;
