import React from "react";

const SvgPhone = props => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      d="M19.273.96L15.21.023a.944.944 0 00-1.074.543L12.261 4.94a.935.935 0 00.27 1.094l2.367 1.937a14.477 14.477 0 01-6.922 6.922L6.04 12.527a.936.936 0 00-1.094-.27L.57 14.132a.949.949 0 00-.547 1.078l.938 4.063a.937.937 0 00.914.726C11.879 20 20 11.894 20 1.874c0-.437-.301-.816-.727-.914z"
      fill="#192E59"
    />
  </svg>
);

export default SvgPhone;
