import { Container, Header, Logo, Main } from "./styles";

import Arrow from "../../icons/LeftArrowHeader";
import BlogList from "../BlogList";
import BlogPost from "../BlogPost";

import { Button } from "@material-ui/core";
import React from "react";

const Blog = ({ history }) => {
  /** Container das páginas de /blog */

  const handleOpenArticle = postId => {
    history.push(`/blog/post?${postId}`);
  };

  return (
    <Container>
      <Header>
        <Button onClick={() => history.push("/")}>
          <Arrow />
          Home
        </Button>
        <Logo src="/assets/lithocenter_branco.png" alt="logo lithocenter" />
      </Header>
      <Main>
        {window.location.pathname.includes("list") ? (
          <BlogList handleOpenArticle={handleOpenArticle} />
        ) : window.location.pathname.includes("post") ? (
          <BlogPost handleOpenArticle={handleOpenArticle} />
        ) : null}
      </Main>
    </Container>
  );
};

export default Blog;
