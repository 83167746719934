import { StyledCarousel, Control, Slide, ContentWrapper } from "./styles";

import getBanners from "../../functions/getBanners";
import scrollWidthOffset from "../../functions/scrollOfset";
import Arrow from "../../icons/CarouselArrow";
import Dot from "../../icons/Dot";
import Button from "../Button";

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { HashLink } from "react-router-hash-link";
import windowSize from "react-window-size";

const HomeCarousel = ({ windowWidth, slidesToShow }) => {
  /**
   * Slide carousel da página principal
   */

  const [banners, setBanners] = useState(null);

  useEffect(() => {
    getBanners().then(banners => {
      console.log("test response", banners);
      setBanners(banners);
    });
  }, []);

  return (
    <>
      <StyledCarousel
        autoplay={true}
        wrapAround={true}
        renderCenterLeftControls={({ previousSlide }) =>
          windowWidth > 600 ? (
            <Control id="prev" onClick={previousSlide}>
              <Arrow />
            </Control>
          ) : null
        }
        renderCenterRightControls={({ nextSlide }) =>
          windowWidth > 600 ? (
            <Control id="next" onClick={nextSlide}>
              <Arrow />
            </Control>
          ) : null
        }
        renderBottomCenterControls={({
          slideCount,
          currentSlide,
          goToSlide,
        }) => {
          let numDots = Math.ceil(slideCount / slidesToShow);
          return [...Array(numDots)].map((e, i) => (
            <Dot
              onClick={() => goToSlide(i)}
              className={`${i === currentSlide ? "active" : ""}`}
            />
          ));
        }}
      >
        {/* {banners && banners.map(banner => (
      <pre>{JSON.stringify(banner, null, 4)}</pre>
    ))}   */}
        {banners &&
          banners.map((banner, i) => (
            <Slide>
              <ContentWrapper>
                <div className="content-text">
                  <h1>{banner.title.rendered}</h1>
                  {ReactHtmlParser(banner.content.rendered)}
                  {/* <pre>{JSON.stringify(banner.meta, null, 4)}</pre> */}
                  {banner.meta &&
                    banner.meta._action_bt &&
                    JSON.parse(banner.meta._action_bt).content !== "" && (
                      <HashLink
                        scroll={el => scrollWidthOffset(el)}
                        to="/#contato"
                      >
                        <Button>
                          {JSON.parse(banner.meta._action_bt).content}
                        </Button>
                      </HashLink>
                    )}
                </div>
              </ContentWrapper>
              <img
                id={`img${i + 1}`}
                src={
                  banner.better_featured_image &&
                  banner.better_featured_image.source_url
                }
                alt={`imagem ${i} do banner`}
              />
            </Slide>
          ))}
      </StyledCarousel>
    </>
  );
};

HomeCarousel.propTypes = {
  windowWidth: PropTypes.number,
  slidesToShow: PropTypes.number,
};

export default windowSize(HomeCarousel);
