import React from "react";

const SvgWhatsapp = props => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      d="M17.005 3.172C15.133 1.368 12.643.375 9.995.375c-5.464 0-9.91 4.28-9.91 9.54 0 1.68.455 3.32 1.321 4.769L0 19.625l5.254-1.328c1.447.76 3.076 1.16 4.737 1.16h.005c5.46 0 10.004-4.28 10.004-9.539 0-2.548-1.125-4.941-2.995-6.746zM9.995 17.85A8.468 8.468 0 015.8 16.746l-.299-.172-3.116.786.83-2.926-.196-.3a7.685 7.685 0 01-1.26-4.22c0-4.37 3.697-7.928 8.242-7.928 2.2 0 4.268.825 5.821 2.325 1.554 1.5 2.51 3.489 2.505 5.607 0 4.375-3.79 7.932-8.33 7.932zm4.518-5.938c-.245-.12-1.464-.696-1.692-.773-.227-.082-.392-.12-.558.12-.165.24-.638.773-.785.937-.143.159-.29.18-.536.06-1.455-.7-2.41-1.25-3.37-2.836-.255-.421.254-.391.727-1.302.08-.159.04-.297-.022-.417-.063-.12-.558-1.293-.764-1.77-.2-.464-.406-.4-.558-.408-.143-.009-.308-.009-.473-.009a.934.934 0 00-.66.297c-.228.24-.867.816-.867 1.989s.889 2.307 1.01 2.466c.124.16 1.745 2.566 4.231 3.601 1.572.653 2.188.71 2.974.597.477-.068 1.464-.575 1.67-1.134.205-.559.205-1.036.142-1.134-.058-.108-.223-.168-.469-.284z"
      fill="#fff"
    />
  </svg>
);

export default SvgWhatsapp;
