import React from "react";

const SvgSearch = props => (
  <svg width={26} height={27} fill="none" {...props}>
    <path
      d="M25.645 22.965l-5.063-5.172a1.204 1.204 0 00-.864-.363h-.827a10.884 10.884 0 002.234-6.64C21.125 4.83 16.397 0 10.562 0 4.729 0 0 4.83 0 10.79c0 5.96 4.728 10.79 10.563 10.79 2.452 0 4.707-.851 6.5-2.283v.845c0 .332.127.649.355.882l5.063 5.172c.477.488 1.25.488 1.721 0l1.438-1.468a1.27 1.27 0 00.005-1.764zM10.563 17.43c-3.59 0-6.5-2.968-6.5-6.64 0-3.668 2.904-6.64 6.5-6.64 3.59 0 6.5 2.967 6.5 6.64 0 3.667-2.905 6.64-6.5 6.64z"
      fill="#DFDFDF"
    />
  </svg>
);

export default SvgSearch;
