import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const SearchField = styled(TextField)`
  &.MuiFormControl-root {
    width: 419px;
    height: 43px;
    align-self: center;
    box-sizing: border-box;
    border-radius: 5px;

    @media screen and (max-width: 450px) {
      width: 100%;
    }

    .MuiInputBase-root {
      height: 43px;
      font-size: 20px;
      line-height: 23px;
      .MuiInputAdornment-positionStart {
        svg > path {
          fill: ${props => props.theme.lightGrey};
        }
      }
      .MuiInputBase-input {
        margin-left: 5px;
        color: ${props => props.theme.lightGrey};

        ::placeholder {
          color: ${props => props.theme.lightGrey};
          opacity: 1;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${props => props.theme.lightGrey};
        border-radius: 5px;
      }
    }
  }
`;
