import React from "react";

const SvgLithocenterLogo = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 190.17199 78.042664"
    height="78.042664"
    width="190.172"
    id="svg2"
    version="1.1"
  >
    <g
      transform="matrix(1.3333333,0,0,-1.3333333,-695.36118,199.84853)"
      id="g10"
    >
      <g id="g16">
        <g clip-path="url(#clipPath22)" id="g18">
          <path
            d="m 537.1465,140.1934 c -5.016,-5.018 -5.016,-13.181 0,-18.195 5.016,-5.018 13.184,-5.018 18.197,0 5.018,5.014 5.018,13.177 0,18.196 -5.013,5.016 -13.179,5.016 -18.197,-0.001 m 19.193,-19.193 c -5.564,-5.568 -14.625,-5.568 -20.189,0 -5.568,5.566 -5.568,14.625 0,20.191 5.566,5.566 14.625,5.566 20.189,0 5.569,-5.566 5.571,-14.625 0,-20.191"
            id="path120"
          />
          <path
            d="m 548.3359,133.1846 c 1.154,-1.154 1.154,-3.027 0,-4.18 -1.154,-1.153 -3.027,-1.153 -4.182,0 -1.152,1.154 -1.152,3.026 0,4.18 1.155,1.156 3.028,1.156 4.182,0"
            id="path124"
          />
          <path
            d="m 524.5469,137.9766 c -2.152,-2.154 -2.154,-5.658 0,-7.812 l 10.385,-10.383 c 2.154,-2.154 5.658,-2.154 7.812,0 l 7.385,7.384 c 2.154,2.155 2.152,5.659 0,7.812 -2.156,2.153 -5.66,2.153 -7.812,0 l -2.981,-2.98 c -0.275,-0.275 -0.723,-0.275 -0.996,0 l -5.982,5.979 c -2.151,2.153 -5.657,2.153 -7.811,0 m 26.578,-11.808 -7.385,-7.385 c -2.703,-2.705 -7.101,-2.705 -9.806,0 l -10.385,10.384 c -2.705,2.704 -2.703,7.106 0,9.808 2.705,2.704 7.103,2.704 9.809,0 l 5.48,-5.48 2.48,2.479 c 2.704,2.705 7.102,2.705 9.807,0 2.705,-2.705 2.705,-7.103 0,-9.806"
            id="path128"
          />
          <path
            d="m 568.6641,95.5664 h 2.897 v 2.629 h 1.017 v -6.721 h -1.017 v 3.138 h -2.897 v -3.138 h -1.014 v 6.721 h 1.014 z"
            id="path132"
          />
          <path
            d="m 578.2617,94.8545 c 0,-0.745 0.249,-1.356 0.75,-1.835 0.494,-0.478 1.069,-0.719 1.72,-0.719 0.702,0 1.298,0.244 1.782,0.736 0.484,0.49 0.727,1.09 0.727,1.798 0,0.716 -0.24,1.317 -0.717,1.801 -0.477,0.488 -1.064,0.734 -1.768,0.734 -0.697,0 -1.291,-0.246 -1.771,-0.734 -0.482,-0.48 -0.723,-1.074 -0.723,-1.781 m -1.022,0.014 c 0,0.943 0.347,1.757 1.04,2.432 0.69,0.681 1.519,1.021 2.49,1.021 0.955,0 1.779,-0.344 2.463,-1.028 0.688,-0.686 1.029,-1.506 1.029,-2.468 0,-0.965 -0.344,-1.786 -1.035,-2.461 -0.694,-0.674 -1.529,-1.01 -2.51,-1.01 -0.867,0 -1.646,0.298 -2.34,0.899 -0.758,0.664 -1.137,1.536 -1.137,2.615"
            id="path136"
          />
          <path
            d="m 592.7246,97.1944 -0.821,-0.488 c -0.154,0.266 -0.301,0.441 -0.443,0.52 -0.142,0.096 -0.328,0.143 -0.56,0.143 -0.28,0 -0.514,-0.079 -0.699,-0.241 -0.186,-0.158 -0.278,-0.352 -0.278,-0.59 0,-0.329 0.242,-0.594 0.729,-0.793 l 0.67,-0.276 c 0.548,-0.221 0.943,-0.489 1.199,-0.808 0.253,-0.317 0.378,-0.704 0.378,-1.167 0,-0.618 -0.207,-1.13 -0.618,-1.532 -0.415,-0.407 -0.932,-0.608 -1.546,-0.608 -0.581,0 -1.063,0.172 -1.445,0.517 -0.372,0.344 -0.607,0.83 -0.7,1.46 l 1.027,0.226 c 0.048,-0.395 0.128,-0.669 0.244,-0.82 0.209,-0.29 0.513,-0.437 0.914,-0.437 0.318,0 0.579,0.107 0.787,0.322 0.211,0.208 0.316,0.479 0.316,0.804 0,0.131 -0.021,0.247 -0.056,0.359 -0.037,0.108 -0.093,0.208 -0.171,0.3 -0.073,0.091 -0.177,0.174 -0.297,0.257 -0.122,0.079 -0.268,0.155 -0.436,0.227 l -0.647,0.268 c -0.922,0.389 -1.381,0.961 -1.381,1.709 0,0.504 0.193,0.923 0.58,1.268 0.386,0.341 0.865,0.512 1.439,0.512 0.777,0 1.379,-0.381 1.815,-1.132"
            id="path140"
          />
          <path
            d="m 598.5234,95.1446 h 0.379 c 0.933,0 1.397,0.356 1.397,1.075 0,0.694 -0.481,1.04 -1.441,1.04 h -0.335 z m 0,-0.942 v -2.728 h -1.015 v 6.721 h 1.149 c 0.563,0 0.989,-0.038 1.274,-0.118 0.293,-0.08 0.547,-0.226 0.767,-0.446 0.387,-0.374 0.579,-0.851 0.579,-1.427 0,-0.615 -0.205,-1.102 -0.617,-1.461 -0.411,-0.36 -0.969,-0.541 -1.666,-0.541 z"
            id="path144"
          />
          <path id="path146" d="m 606.724,91.475 h -1.013 v 6.721 h 1.013 z" />
          <path
            d="m 613.5781,97.2422 v -5.768 h -1.011 v 5.768 h -1.547 v 0.953 h 4.101 v -0.953 z"
            id="path150"
          />
          <path
            d="m 622.5801,94.0616 -0.997,2.282 -1.044,-2.282 z m 0.415,-0.953 h -2.878 l -0.747,-1.634 h -1.095 l 3.326,7.144 3.208,-7.144 h -1.108 z"
            id="path154"
          />
          <path
            d="m 630.0488,98.1953 v -5.766 h 1.978 v -0.955 h -2.992 v 6.721 z"
            id="path158"
          />
          <path
            d="m 643.5264,92.4297 h 0.456 c 0.456,0 0.835,0.047 1.136,0.144 0.304,0.099 0.574,0.262 0.819,0.48 0.501,0.459 0.749,1.048 0.749,1.781 0,0.739 -0.245,1.336 -0.739,1.795 -0.445,0.405 -1.1,0.612 -1.965,0.612 h -0.456 z m -1.014,-0.955 v 6.721 h 1.408 c 0.679,0 1.212,-0.07 1.605,-0.202 0.419,-0.129 0.803,-0.352 1.144,-0.665 0.693,-0.63 1.04,-1.463 1.04,-2.494 0,-1.034 -0.363,-1.869 -1.082,-2.506 -0.365,-0.32 -0.745,-0.539 -1.142,-0.666 -0.372,-0.127 -0.897,-0.188 -1.58,-0.188 z"
            id="path162"
          />
          <path id="path164" d="m 653.385,91.475 h -1.015 v 6.721 h 1.015 z" />
          <path
            d="m 661.9209,94.0616 -0.997,2.282 -1.043,-2.282 z m 0.414,-0.953 h -2.878 l -0.749,-1.634 h -1.092 l 3.324,7.144 3.21,-7.144 h -1.11 z"
            id="path168"
          />
          <path
            d="m 567.6504,149.5059 h 2.568 v -15.79 h 7.692 v -1.093 h -10.26 z"
            id="path172"
          />
          <path
            id="path174"
            d="m 579.73,149.506 h 2.564 v -16.883 h -2.564 z"
          />
          <path
            d="m 597.9785,149.5059 v -1.094 h -5.529 v -15.789 h -2.569 v 15.789 h -5.523 v 1.094 z"
            id="path178"
          />
          <path
            d="m 602.584,140.7188 v -8.096 h -2.567 v 16.883 h 2.567 v -7.691 h 7.666 v 7.691 h 2.568 v -16.883 h -2.568 v 8.096 z"
            id="path182"
          />
          <path
            d="m 624.2383,133.0684 c 0.875,0 1.649,0.199 2.32,0.602 0.67,0.406 1.238,0.965 1.7,1.687 0.458,0.722 0.804,1.575 1.04,2.561 0.236,0.985 0.352,2.063 0.352,3.235 0,1.16 -0.116,2.227 -0.352,3.197 -0.236,0.967 -0.582,1.8 -1.04,2.503 -0.462,0.697 -1.03,1.244 -1.7,1.629 -0.671,0.385 -1.445,0.576 -2.32,0.576 -0.889,0 -1.671,-0.191 -2.344,-0.576 -0.669,-0.385 -1.234,-0.932 -1.692,-1.629 -0.454,-0.703 -0.8,-1.536 -1.028,-2.503 -0.233,-0.97 -0.347,-2.037 -0.347,-3.197 0,-1.172 0.114,-2.25 0.347,-3.235 0.228,-0.986 0.574,-1.839 1.028,-2.561 0.458,-0.722 1.023,-1.281 1.692,-1.687 0.673,-0.403 1.455,-0.602 2.344,-0.602 m 0,-0.844 c -1.182,0 -2.285,0.234 -3.311,0.701 -1.021,0.468 -1.914,1.107 -2.669,1.917 -0.758,0.812 -1.352,1.757 -1.786,2.838 -0.431,1.083 -0.648,2.242 -0.648,3.473 0,0.806 0.096,1.583 0.291,2.329 0.194,0.75 0.476,1.443 0.835,2.091 0.363,0.643 0.796,1.232 1.308,1.764 0.509,0.531 1.076,0.984 1.695,1.36 0.625,0.38 1.299,0.672 2.023,0.88 0.725,0.208 1.481,0.309 2.262,0.309 0.793,0 1.546,-0.101 2.269,-0.309 0.719,-0.208 1.393,-0.5 2.02,-0.88 0.625,-0.376 1.19,-0.829 1.7,-1.36 0.503,-0.532 0.941,-1.121 1.305,-1.764 0.364,-0.648 0.643,-1.341 0.841,-2.091 0.193,-0.746 0.294,-1.523 0.294,-2.329 0,-0.823 -0.101,-1.616 -0.294,-2.369 -0.198,-0.756 -0.477,-1.464 -0.841,-2.128 -0.364,-0.661 -0.802,-1.266 -1.305,-1.807 -0.51,-0.546 -1.075,-1.011 -1.7,-1.4 -0.627,-0.394 -1.301,-0.693 -2.02,-0.905 -0.723,-0.213 -1.476,-0.32 -2.269,-0.32"
            id="path186"
          />
          <path
            d="m 569.6562,120.8389 c 0,-1.179 0.16,-2.24 0.483,-3.174 0.324,-0.942 0.765,-1.736 1.33,-2.383 0.564,-0.649 1.228,-1.147 1.997,-1.49 0.768,-0.344 1.602,-0.517 2.499,-0.517 0.538,0 1.058,0.119 1.552,0.345 0.5,0.229 0.965,0.529 1.39,0.9 0.425,0.368 0.806,0.783 1.145,1.248 0.339,0.461 0.613,0.93 0.829,1.406 l 1.081,-0.767 c -0.314,-0.568 -0.687,-1.111 -1.116,-1.637 -0.431,-0.519 -0.925,-0.984 -1.491,-1.384 -0.564,-0.405 -1.201,-0.726 -1.91,-0.966 -0.71,-0.234 -1.501,-0.354 -2.377,-0.354 -1.267,0 -2.418,0.233 -3.448,0.701 -1.032,0.468 -1.916,1.098 -2.657,1.901 -0.735,0.797 -1.306,1.728 -1.708,2.791 -0.402,1.066 -0.605,2.193 -0.605,3.38 0,0.791 0.094,1.562 0.277,2.312 0.184,0.754 0.452,1.459 0.798,2.116 0.352,0.657 0.777,1.259 1.281,1.807 0.502,0.549 1.071,1.023 1.705,1.414 0.631,0.397 1.322,0.701 2.075,0.915 0.753,0.22 1.554,0.325 2.41,0.325 0.812,0 1.561,-0.12 2.249,-0.36 0.685,-0.244 1.312,-0.563 1.875,-0.963 0.565,-0.4 1.065,-0.858 1.508,-1.379 0.443,-0.523 0.82,-1.071 1.134,-1.638 l -1.116,-0.818 c -0.635,1.371 -1.35,2.369 -2.143,3 -0.788,0.634 -1.698,0.95 -2.727,0.95 -0.883,0 -1.709,-0.177 -2.476,-0.535 -0.766,-0.357 -1.44,-0.866 -2.007,-1.526 -0.573,-0.665 -1.023,-1.47 -1.348,-2.414 -0.325,-0.947 -0.489,-2.016 -0.489,-3.206"
            id="path190"
          />
          <path
            d="m 595.6738,129.3467 v -1.094 h -8.047 v -6.035 h 5.389 v -1.099 h -5.389 v -7.562 h 8.047 v -1.093 h -10.613 v 16.883 z"
            id="path194"
          />
          <path
            d="m 598.2197,129.3467 h 1.439 l 10.005,-12.351 h 0.115 v 12.351 h 1.131 v -17.281 h -0.601 l -10.834,13.728 h -0.094 v -13.33 h -1.161 z"
            id="path198"
          />
          <path
            d="m 626.5791,129.3467 v -1.094 h -5.527 v -15.789 h -2.568 v 15.789 h -5.526 v 1.094 z"
            id="path202"
          />
          <path
            d="m 639.2295,129.3467 v -1.094 h -8.048 v -6.035 h 5.389 v -1.099 h -5.389 v -7.562 h 8.048 v -1.093 h -10.612 v 16.883 z"
            id="path206"
          />
          <path
            d="m 644.3428,121.2471 h 1.485 c 0.574,0 1.106,0.087 1.594,0.255 0.486,0.172 0.908,0.412 1.264,0.719 0.359,0.312 0.636,0.686 0.837,1.128 0.202,0.441 0.299,0.936 0.299,1.491 0,0.51 -0.08,0.976 -0.236,1.401 -0.158,0.421 -0.38,0.786 -0.668,1.087 -0.289,0.303 -0.628,0.538 -1.023,0.708 -0.397,0.171 -0.828,0.256 -1.295,0.256 h -2.257 z m -2.569,8.1 h 5.298 c 0.913,0 1.716,-0.106 2.405,-0.324 0.692,-0.216 1.265,-0.519 1.726,-0.912 0.459,-0.39 0.807,-0.853 1.043,-1.392 0.237,-0.539 0.351,-1.126 0.351,-1.766 0,-0.526 -0.088,-1.018 -0.271,-1.476 -0.179,-0.459 -0.433,-0.873 -0.759,-1.241 -0.326,-0.37 -0.719,-0.689 -1.179,-0.957 -0.462,-0.265 -0.967,-0.476 -1.521,-0.629 l 5.561,-8.186 h -2.982 l -5.307,7.928 h -1.796 v -7.928 h -2.569 z"
            id="path210"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgLithocenterLogo;
