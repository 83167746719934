//styles
import { SearchField } from "./styles";

//material ui
import Search from "../../icons/Search";

import { InputAdornment } from "@material-ui/core";
//icons
import React from "react";

const SearchBar = ({ ...props }) => {
  return (
    <SearchField
      variant="outlined"
      type="text"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default SearchBar;
