import axios from "axios";

const getSobre = async () => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_HOST}/wp/v2/posts?slug=sobre-litho-center`
    )
    .then(res => {
      console.log("getSobre: ", res);
      return res;
    })
    .catch(err => {
      console.log("Ocorreu um erro: ", err);
    });
};

export default getSobre;
