import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
  }

  form {
    width: 632px;
    min-width: 316px;
    display: flex;
    flex-flow: column;
    margin: 46px 0 88px 0;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
`;

export const TextInput = styled(TextField)``;

export const InputArea = styled.div`
  display: flex;
  flex-flow: column;
  margin: 7.5px 0;

  label {
    margin-bottom: 10px;

    span {
      font-family: Arial, Helvetica, sans-serif;
      color: #ff0000;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  ${InputArea} {
    width: 47.5%;
  }

  @media only screen and (max-width: 600px) {
    flex-flow: column;
    ${InputArea} {
      width: 100%;
    }
  }
`;

export const BtnRow = styled.div`
  display: flex;
  margin: 35px 0;

  .MuiButton-root {
    &:first-of-type {
      margin-right: 7px;
    }
    &:last-of-type {
      margin-left: 7px;

      .MuiButton-label {
        svg {
          margin-right: 8px;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    flex-flow: column;
    align-items: center;

    .MuiButton-root {
      &:first-of-type,
      &:last-of-type {
        margin: 0 0 24px 0;
      }
    }
  }
`;

export const Warning = styled.span`
  color: red;
  align-self: center;
`;
