import { Button } from "@material-ui/core";
import styled from "styled-components";

export const BlueBtn = styled(Button)`
  &.MuiButton-root {
    background: ${props => props.theme.primaryBlue};
    border-radius: 10px;
    color: #fff;
    text-transform: none;
    padding: 20px;
    transition: 0.5s all;

    &:hover {
      background: #192e4e;
    }

    &.green {
      background: ${props => props.theme.primaryGreen};
    }

    &.Mui-disabled {
      opacity: 0.7;
      .MuiButton-label {
        color: #fff;
      }
    }

    .MuiButton-label {
      font-size: 20px;
      line-height: 23px;
    }
  }
`;

export const OutlinedBtn = styled(Button)`
  &.MuiButton-root {
    background: #fff;
    border-radius: 10px;
    color: ${props => props.theme.primaryBlue};
    text-transform: none;
    padding: 20px;
    transition: 0.5s all;
    border: solid 1.5px ${props => props.theme.primaryBlue};
    .MuiButton-label {
      font-size: 20px;
      line-height: 23px;
      svg > path {
        stroke: ${props => props.theme.primaryBlue};
      }
    }

    &:hover {
      background: ${props => props.theme.primaryBlue};
      color: #fff;
      .MuiButton-label {
        svg > path {
          stroke: #fff;
        }
      }
    }
  }
`;

export const TransparentBtn = styled(Button)`
  &.MuiButton-root {
    padding: 5px;
    .MuiButton-label {
      text-transform: none;
      font-size: 20px;
      line-height: 23px;
      color: ${props => props.theme.primaryBlue};

      svg > path {
        stroke: ${props => props.theme.primaryBlue};
      }
    }
  }
`;
